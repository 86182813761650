import React from 'react';
import NavItem from '@plone/volto/components/theme/Navigation/NavItem';

const NavItems = ({ items, lang }) => {
  return (
    <>
      {items
        .filter((o) => o?.url !== '')
        .map((item) => (
          <NavItem item={item} lang={lang} key={item.url} />
        ))}
    </>
  );
};

export default NavItems;
