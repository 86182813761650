import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Logo } from '@plone/volto/components';

const Footer = () => {
  const current_year = new Date().getFullYear();

  return (
    <footer>
      <div className="footer">
        <Segment vertical padded textAlign="center">
          <Container>
            <div className="text">
              <span>Deutsche Gesellschaft für Systemische Therapie, Beratung und Familientherapie e. V.</span>
              <span>
                Jakordenstraße 23 · 50668 Köln · +49 (0) 221 168860-0 · <a href="mailto:info@dgsf.org">info@dgsf.org</a>
              </span>
            </div>
            <div className="links">
              <span className="item impressum">
                <Link to="/impressum">Impressum</Link>
              </span>
              <span className="item bordered">
                <Link to="/datenschutz">Datenschutzerklärung</Link>
              </span>
            </div>
            <div className="logo">
              <Logo wide />
            </div>
            <span className="copyright">© DGSF {current_year}</span>
          </Container>
        </Segment>
      </div>
    </footer>
  );
};

export default Footer;
