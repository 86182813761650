/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment, Form, Input } from 'semantic-ui-react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import zoomSVG from '@plone/volto/icons/zoom.svg';
// import closeSVG from 'volto-dgsf/icons/close.svg';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';

import { Anontools, LanguageSelector, Logo, Navigation, Icon } from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
      active: false,
    };
    this.inputFocus = this.utilizeFocus();
  }
  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event, { value }) {
    this.setState({
      text: value,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const path = this.props.pathname?.length > 0 ? `&path=${encodeURIComponent(this.props.pathname)}` : '';
    this.props.history.push(`/search?SearchableText=${encodeURIComponent(this.state.text)}${path}`);
    event.preventDefault();
  }

  utilizeFocus() {
    const ref = React.createRef();
    const setFocus = () => {
      ref.current && ref.current.focus();
    };

    return { setFocus, ref };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    if (this.searchbar.current && doesNodeContainClick(this.searchbar.current, e)) return;
    this.setState({ active: false });
  };

  searchbar = React.createRef();
  input = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="header">
            <div
              ref={this.searchbar}
              className={cx('search-bar', {
                active: this.state.active,
              })}
            >
              <Container>
                <Form action="/search" onSubmit={this.onSubmit}>
                  <Form.Field className="searchbox">
                    <Input
                      ref={this.inputFocus.ref}
                      aria-label={this.props.intl.formatMessage(messages.search)}
                      onChange={this.onChangeText}
                      name="SearchableText"
                      value={this.state.text}
                      transparent
                      autoComplete="off"
                      placeholder={this.props.intl.formatMessage(messages.searchSite)}
                      title={this.props.intl.formatMessage(messages.search)}
                    />
                    {/* <button
                    className="clear"
                    onClick={() => this.setState({ active: false })}
                  >
                    <Icon name={closeSVG} size="20px" />
                  </button> */}
                    <button aria-label={this.props.intl.formatMessage(messages.search)}>
                      <Icon name={zoomSVG} size="40px" />
                    </button>
                  </Form.Field>
                </Form>
              </Container>
            </div>
            <div className="tools-search-wrapper">
              <LanguageSelector />
              <div className="tools">
                <Anontools />
              </div>
            </div>
            <div className="logo-nav-wrapper">
              <div className="logo">
                <Logo />
              </div>
              <Navigation pathname={this.props.pathname} />
              <div className="search">
                <button
                  onClick={() => {
                    this.setState({ active: !this.state.active });
                    this.inputFocus.setFocus();
                  }}
                >
                  <Icon name={zoomSVG} size="48px" />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Segment>
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(injectIntl(Header));
