import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { getUser } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import config from '@plone/volto/registry';

const Anontools = () => {
  const { settings } = config;
  const dispatch = useDispatch();

  const userId = useSelector((state) => (state.userSession.token ? jwtDecode(state.userSession.token).sub : ''));
  const user = useSelector((state) => state.users.user);
  const token = useSelector((state) => state.userSession.token);
  const content = useSelector((state) => state.content.data);
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId)).then((resp) => {
        if (resp) {
          setUsername(resp.username);
          if (resp.roles.includes('Manager' || 'Site Administrator')) {
            setIsAdmin(true);
          }
        }
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userId]);

  return !token ? (
    <>
      <BodyClass className="anon" />
      <Menu pointing secondary floated="right">
        <Menu.Item>
          <Link aria-label="login" to={`/login${content?.['@id'] ? `?return_url=${content['@id'].replace(settings.apiPath, '')}` : ''}`}>
            <FormattedMessage id="Log in" defaultMessage="Log in" />
          </Link>
        </Menu.Item>
        {settings.showSelfRegistration && (
          <Menu.Item>
            <Link aria-label="register" to="/register">
              <FormattedMessage id="Register" defaultMessage="Register" />
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </>
  ) : (
    <>
      <BodyClass className={isAdmin ? 'admin' : 'anon'} />
      <Menu pointing secondary floated="right">
        <Menu.Item className={token ? 'loggedin' : ''}>
          <Link
            aria-label="account"
            to={
              username
                ? `/Members/${user.username?.replace('DGSF-', 'DGSF--')}`
                : user.username
                ? `/Members/${user.username?.replace('DGSF-', 'DGSF--')}`
                : userId
                ? `/Members/${userId?.replace('DGSF-', 'DGSF--')}`
                : `/login${content?.['@id'] ? `?return_url=${content['@id'].replace(settings.apiPath, '')}` : ''}`
            }
          >
            Mitgliederbereich
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Anontools;
