import React from 'react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const MemberListingTemplate = ({ items, isEditMode }) => {
  return (
    <>
      <div className="member-listing-template">
        <div className="items">
          {items.map((item) => (
            <div key={item.UID} className="member-item">
              <div className="content">
                <div className="listing-item" key={item['@id']}>
                  <div className="text">
                    <div className="listing-body">
                      <div>
                        <ConditionalLink item={item} condition={!isEditMode}>
                          <h3>{item.title ? item.title : item.id}</h3>
                        </ConditionalLink>
                      </div>
                      <div className="information">
                        {item?.city && <span>{item.city}</span>}
                        {item?.street && <span>,&nbsp;{item.street}</span>}
                        {item?.phone && <span>,&nbsp;{item.phone}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MemberListingTemplate;
