import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  memberList: {
    id: 'Persons List',
    defaultMessage: 'Persons List',
  },
  item: {
    id: 'Person',
    defaultMessage: 'Person',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add Person',
    defaultMessage: 'Add Person',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  hideEmail: {
    id: 'Hide Email Adress',
    defaultMessage: 'Hide Email Adress',
  },
  hidePhone: {
    id: 'Hide Phone Number',
    defaultMessage: 'Hide Phone Number',
  },
});

const itemSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['image_field', 'portrait', 'firstname', 'name', 'academic', 'salutation', 'email', 'phone', 'fax'],
        allowExternals: false,
      },
    },
    required: [],
  };
};

export const MembersBlockSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.memberList),
    block: 'members',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline', 'columns'],
      },
    ],

    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
      },
      columns: {
        widget: 'object_list',
        title: intl.formatMessage(messages.items),
        schema: itemSchema,
        // fieldsets: [
        //   {
        //     id: 'default',
        //     title: 'Default',
        //     fields: ['hide_email', 'hide_phone'],
        //   },
        // ],
        // properties: {
        //   hide_email: {
        //     title: intl.formatMessage(messages.hideEmail),
        //     widget: CheckboxWidget,
        //   },
        //   hide_phone: {
        //     title: intl.formatMessage(messages.hidePhone),
        //     widget: CheckboxWidget,
        //   },
        // },
      },
    },
    required: [],
  };
};
