import React from 'react';
import { Message, Grid } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import MembersBody from './Body';
import teaserHeroTopTemplate from '../../../icons/teaserhero-top-template.svg';
import { withBlockExtensions } from '@plone/volto/helpers';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage: 'Please choose an existing content as source for this element',
  },
});

const MembersBlockView = (props) => {
  const { data, isEditMode } = props;
  const intl = useIntl();

  return (
    <div className="members-block">
      {(data.columns?.length === 0 || !data.columns) && isEditMode && (
        <Message>
          <div className="teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.columns?.length > 0 && (
        <div>
          {data.headline && <h2 className="headline">{data.headline}</h2>}
          <Grid columns="1">
            <Grid.Row>
              {data.columns &&
                data.columns.map((item, index) => (
                  <Grid.Column key={index}>
                    <MembersBody key={item.id} data={item} isEditMode={isEditMode} />
                  </Grid.Column>
                ))}
            </Grid.Row>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default withBlockExtensions(MembersBlockView);
