import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { resolveExtension } from '@plone/volto/helpers/Extensions/withBlockExtensions';

function SelectFacetFilterListEntry(props) {
  const { facet, isEditMode, setFacets, facets, data } = props;
  const facet_index = data.facets.findIndex((obj) => {
    return obj.field.value === facet;
  });

  const facetSettings = data.facets[facet_index];
  const { types: facetWidgetTypes } = config.blocks.blocksConfig.search.extensions.facetWidgets;

  const index = props.querystring?.indexes?.[facet] || {};
  const { stateToValue } = resolveExtension('type', facetWidgetTypes, facetSettings);
  const selectedValue = facets[facetSettings?.field?.value];
  let value = stateToValue({ facetSettings, index, selectedValue });

  return typeof facets[facet] === 'string' ? (
    <Label size="small">
      {facets[facet]}
      <Icon
        name="delete"
        onClick={() => {
          !isEditMode &&
            setFacets({
              ...facets,
              [facet]: '',
            });
        }}
      />
    </Label>
  ) : (
    <>
      {facets[facet].map((entry, i) => {
        return (
          <Label size="small" key={i}>
            {value?.find((o) => o.value === entry)?.label}
            <Icon
              name="delete"
              onClick={() => {
                const entries = facets[facet].filter((item) => item !== entry);
                !isEditMode &&
                  setFacets({
                    ...facets,
                    [facet]: entries,
                  });
              }}
            />
          </Label>
        );
      })}
    </>
  );
}

export default SelectFacetFilterListEntry;
