import React from 'react';
import { Header, Input, Select } from 'semantic-ui-react';

import zipcodes from './zipcodes';

const DistanceFacet = (props) => {
  const { facet, isEditMode, onChange, value } = props;

  return (
    <div className="checkbox-facet ui form">
      <Header as="h4">{facet?.title ?? facet?.field?.label}</Header>
      <div className="distance-widget-input">
        <Input
          placeholder="PLZ"
          value={value[0]}
          onChange={(e, { value: newValue }) => {
            onChange(facet.field.value, [newValue, value[1]]);
          }}
        />
      </div>
      <div className="distance-widget-select">
        <Select
          disabled={isEditMode}
          onChange={(e, { value: newValue }) => {
            onChange(facet.field.value, [value[0], newValue]);
          }}
          options={[
            { key: '5', text: '5 km', value: 5 },
            { key: '10', text: '10 km', value: 10 },
            { key: '20', text: '20 km', value: 20 },
            { key: '50', text: '50 km', value: 50 },
            { key: '100', text: '100 km', value: 100 },
          ]}
          value={value[1]}
        />
      </div>
    </div>
  );
};

DistanceFacet.stateToValue = ({ facetSettings, index, selectedValue }) => {
  return selectedValue || ['', 5];
};

DistanceFacet.valueToQuery = ({ value, facet }) => {
  if (!value || !(value[0] in zipcodes)) {
    return null;
  }

  return [
    {
      i: 'long',
      o: 'plone.app.querystring.operation.int.lessThan',
      v: zipcodes[value[0]][0] + value[1],
    },
    {
      i: 'long',
      o: 'plone.app.querystring.operation.int.largerThan',
      v: zipcodes[value[0]][0] - value[1],
    },
    {
      i: 'lat',
      o: 'plone.app.querystring.operation.int.lessThan',
      v: zipcodes[value[0]][1] + value[1],
    },
    {
      i: 'lat',
      o: 'plone.app.querystring.operation.int.largerThan',
      v: zipcodes[value[0]][1] - value[1],
    },
  ];
};

export default DistanceFacet;
