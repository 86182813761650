/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';

//eslint-disable-next-line
import MemberImportForm from 'addons/volto-dgsf/src/components/View/MemberImportForm';
//eslint-disable-next-line
import MemberDeleteForm from 'addons/volto-dgsf/src/components/View/MemberDeleteForm';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      // Add your routes here
      {
        path: '/member-import',
        exact: true,
        component: MemberImportForm,
      },
      {
        path: '/delete-members',
        exact: true,
        component: MemberDeleteForm,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
