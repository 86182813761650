import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button } from 'semantic-ui-react';
import jwtDecode from 'jwt-decode';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import ObjectBrowserWidget from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';
import CheckboxWidget from '@plone/volto/components/manage/Widgets/CheckboxWidget';
import { deleteMembers } from 'volto-dgsf/actions';
import { getUser } from '@plone/volto/actions';
import { Unauthorized, Icon } from '@plone/volto/components';

import closeSVG from 'volto-dgsf/icons/close.svg';

const messages = defineMessages({
  memberImport: {
    id: 'Member Import',
    defaultMessage: 'Member Import',
  },
  chooseFile: {
    id: 'Choose your file here. The required file type is .csv. The csv file must not contain any semicolons.',
    defaultMessage: 'Choose your file here. The required file type is .csv. The csv file must not contain any semicolons.',
  },
  loading: {
    id: 'The import has been started. This can take a few minutes.',
    defaultMessage: 'The import has been started. This can take a few minutes.',
  },
  loaded: {
    id: 'The import was successful.',
    defaultMessage: 'The import was successful.',
  },
  totalOf: {
    id: 'A total of',
    defaultMessage: 'A total of',
  },
  fileSource: {
    id: 'Source',
    defaultMessage: 'Source',
  },
});

const MemberDeleteForm = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const userId = useSelector((state) => (state.userSession.token ? jwtDecode(state.userSession.token).sub : ''));
  const [deleteAfter, setDeleteAfter] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId)).then((resp) => {
        setUserRoles(resp.roles);
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onStartImport = (url, deleteAfter = false) => {
    if (url !== '') {
      setLoading(true);
      dispatch(deleteMembers(url, deleteAfter)).then((resp) => {
        debugger;
        setErrorMsg(resp.error);
        setDetails(resp.details);
        setLoading(false);
        setLoaded(true);
      });
    }
  };

  return (
    <Container>
      {userRoles.includes('Manager' || 'Site Administrator') ? (
        <div className="member-import">
          <h1>Mitglieder Löschliste</h1>
          <p>{intl.formatMessage(messages.chooseFile)}</p>
          {!fileUrl && (
            <ObjectBrowserWidget
              id="source"
              title={intl.formatMessage(messages.fileSource)}
              fieldSet="default"
              onChange={(id, value) => {
                setFileUrl(value?.[0]?.getURL ? value?.[0]?.getURL : value?.[0]?.['@id']);
              }}
            />
          )}

          {fileUrl && (
            <div className="chosen">
              <div className="remove">
                <div>
                  <p>
                    <FormattedMessage id="You have chosen the following file:" defaultMessage="You have chosen the following file:" />
                  </p>
                </div>
                <div className="x">
                  <span className="filename">{fileUrl}</span>{' '}
                  <div className="icon-wrapper">
                    <Button onClick={() => setFileUrl('')}>
                      <Icon name={closeSVG} size="20px" />
                    </Button>
                  </div>
                </div>
              </div>
              <p className="close-prompt">
                <FormattedMessage
                  id="You can close the object browser on the right after choosing the source"
                  defaultMessage="You can close the object browser on the right after choosing the source"
                />
              </p>
            </div>
          )}
          <CheckboxWidget
            id="deleteafter"
            title="Delete File after Import"
            value={deleteAfter}
            onChange={(event, { checked }) => {
              setDeleteAfter(!deleteAfter);
            }}
          />
          <Button disabled={fileUrl === '' ? true : false} onClick={() => onStartImport(flattenToAppURL(fileUrl), deleteAfter)} className="start">
            Löschung starten
          </Button>
          <div className="after">
            {errorMsg && <p className="fail">{errorMsg}</p>}
            {loading && <p>{intl.formatMessage(messages.loading)}</p>}
            {loading && (
              <div class="spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {loaded && !errorMsg && (
              <p className="success">
                <span className="green">{intl.formatMessage(messages.loaded)}</span>
                <p>
                  <FormattedMessage
                    id="A total of {term} member entries have been found."
                    defaultMessage="A total of {term} member entries have been found."
                    values={{
                      term: <span>{details?.member_entries?.toString()}</span>,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="{term} member profiles have been deleted."
                    defaultMessage="{term} member profiles have been deleted."
                    values={{
                      term: <span>{details?.deleted_member_profiles?.toString()}</span>,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="{term} user accounts have been deleted."
                    defaultMessage="{term} user accounts have been deleted."
                    values={{
                      term: <span>{details?.deleted_user_accounts?.toString()}</span>,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="The deletion of {term} entries has been skipped."
                    defaultMessage="The deletion of {term} entries has been skipped."
                    values={{
                      term: <span>{details?.skipped_member_profiles?.toString()}</span>,
                    }}
                  />
                </p>
                {details?.skipped_ids && details?.skipped_ids.length > 0 && (
                  <ul>
                    <h3>Die folgenden Profile wurden nicht gelöscht, da sie nicht existieren:</h3>
                    {details.skipped_ids.map((item) => (
                      <li>
                        <a href={`/Members/${item}`}>{item}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </p>
            )}
          </div>
        </div>
      ) : (
        <Unauthorized />
      )}
    </Container>
  );
};

export default MemberDeleteForm;
