import React from 'react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { EffectiveDate } from 'volto-dgsf/helpers';

const WeiterbildungsListingTemplate = ({ items, isEditMode }) => {
  return (
    <>
      <div className="weiterbildungs-listing-template">
        <div className="items">
          {items.map((item) => (
            <div key={item.UID} className="weiterbildungs-item">
              <div className="content">
                <div className="listing-item" key={item['@id']}>
                  <div className="text">
                    <div className="listing-body">
                      <ConditionalLink item={item} condition={!isEditMode}>
                        <h3>{item.title ? item.title : item.id}</h3>
                      </ConditionalLink>
                      <div className="information">
                        <div className="dates">
                          <EffectiveDate item={item} type="start" /> -
                          <EffectiveDate item={item} type="end" />
                        </div>
                        {item?.city && <span>,&nbsp;{item.city}</span>}
                      </div>
                      <div className="event-type">{item?.event_type && <p>{item.event_type}</p>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WeiterbildungsListingTemplate;
