import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import config from '@plone/volto/registry';
import { getContent } from '@plone/volto/actions';
import { UniversalLink, FormattedDate } from '@plone/volto/components';
import { parseDateTime } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import { getBlocksFieldname, getBlocksLayoutFieldname, hasBlocksData, getBaseUrl } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

export const WeiterbildungView_ = (props) => {
  const { content, location } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const moment = props.moment.default;
  const lang = useSelector((state) => state.intl.locale);

  const format = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  React.useEffect(() => {
    if (content?.creators && content.creators?.length > 0) {
      dispatch(getContent(flattenToAppURL(`${config.settings.apiPath}/Members/${content.creators[0]}`), null, 'member-profile'));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const profile = useSelector((state) => state.content.subrequests?.['member-profile']?.data);

  const start = parseDateTime(lang, content?.start, undefined, moment).toISOString();
  const end = parseDateTime(lang, content?.end, undefined, moment).toISOString();

  return (
    <Container id="page-document" className="weiterbildung-view">
      <div className="headtitle-line">
        <FormattedDate date={start} format={format} includeTime />
        <span> Uhr</span> - <FormattedDate date={end} format={format} includeTime />
        <span> Uhr</span>
      </div>
      {hasBlocksData(content) ? (
        map(content[blocksLayoutFieldname].items, (block) => {
          const Block = config.blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']]?.['view'] || null;
          return (
            Block !== null &&
            (content[blocksFieldname][block]?.['@type'] == 'title' ? (
              <Block key={block} id={block} properties={content} data={content[blocksFieldname][block]} path={getBaseUrl(location?.pathname || '')} />
            ) : null)
          );
        })
      ) : (
        <>
          <h1 className="documentFirstHeading">{content.title}</h1>
          {content.description && <p className="documentDescription">{content.description}</p>}
          {content.text && (
            <div
              dangerouslySetInnerHTML={{
                __html: content.text.data,
              }}
            />
          )}
        </>
      )}
      <div className="information">
        <div>
          <ul>
            {profile && profile?.title !== 'admin' && (
              <li className="item">
                <h4>Kontaktdaten</h4>
                <UniversalLink href={profile?.['@id']}>
                  <p>{profile?.title}</p>
                </UniversalLink>
                {profile?.email && <UniversalLink href={`mailto:${profile.email}`}>{profile.email}</UniversalLink>}
                {profile?.phone && <p>{profile.phone}</p>}
              </li>
            )}
            {(content?.street_and_number || content?.postal_code || content?.city || content?.federal_state) && (
              <li className="item">
                <h4>Standort</h4>
                {content?.street_and_number && <p>{content.street_and_number}</p>}
                {(content?.postal_code || content?.city) && (
                  <p>
                    {content?.postal_code}
                    {content?.postal_code && ' '}
                    {content?.city}
                  </p>
                )}
                {content?.federal_state && <p>{content.federal_state?.title}</p>}
              </li>
            )}
            {content?.event_type && (
              <li className="item">
                <h4>Veranstaltungsart</h4>
                <p>{content.event_type?.title}</p>
              </li>
            )}
            <li className="item">
              <h4>Termindaten</h4>
              <div>
                <FormattedDate date={start} format={format} includeTime />
                <span> Uhr -</span>
              </div>
              <div>
                <FormattedDate date={end} format={format} includeTime />
                <span> Uhr</span>
              </div>
            </li>
            <li className="item">
              <h4>Barrierefrei</h4>
              <p>{content?.accessible ? 'Ja' : 'Nein'}</p>
            </li>
            {content?.attendees_amount && (
              <li className="item">
                <h4>Teilnehmerzahl</h4>
                <p>{content.attendees_amount}</p>
              </li>
            )}
            {content?.leader && (
              <li className="item">
                <h4>Referentin</h4>
                <p className={cx({ small: content.leader?.length > 300 })}>{content.leader}</p>
              </li>
            )}
            {content?.target_group && (
              <li className="item">
                <h4>Zielgruppe</h4>
                <p className={cx({ small: content.target_group?.length > 300 })}>{content.target_group}</p>
              </li>
            )}
            {content?.information && (
              <li className="item">
                <h4>Zielgruppe/Kurzinfo</h4>
                <p className="small">{content.information}</p>
              </li>
            )}
            {content?.further_information && (
              <li className="item">
                <h4>Weitere Informationen</h4>
                <p className="small">{content.further_information}</p>
              </li>
            )}
          </ul>
        </div>
      </div>
      {hasBlocksData(content) ? (
        map(content[blocksLayoutFieldname].items, (block) => {
          const Block = config.blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']]?.['view'] || null;
          return Block !== null ? (
            content[blocksFieldname][block]?.['@type'] !== 'title' ? (
              <Block key={block} id={block} properties={content} data={content[blocksFieldname][block]} path={getBaseUrl(location?.pathname || '')} />
            ) : null
          ) : (
            <div key={block}>
              {intl.formatMessage(messages.unknownBlock, {
                block: content[blocksFieldname]?.[block]?.['@type'],
              })}
            </div>
          );
        })
      ) : (
        <>
          <h1 className="documentFirstHeading">{content.title}</h1>
          {content.description && <p className="documentDescription">{content.description}</p>}
          {content.text && (
            <div
              dangerouslySetInnerHTML={{
                __html: content.text.data,
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export const WeiterbildungView = injectLazyLibs(['moment'])(WeiterbildungView_);
