/**
 * Navigation actions.
 * @module actions/navigation/navigation
 */

import { DELETE_MEMBER_IMPORT } from 'volto-dgsf/constants/ActionTypes';

export default function deleteMembers(url, delete_after) {
  return {
    type: DELETE_MEMBER_IMPORT,
    request: {
      op: 'del',
      path: `@delete-members?url=${url}&delete_after=${delete_after.toString()}`,
    },
  };
}
