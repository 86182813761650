import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';

import avatarSVG from 'volto-dgsf/icons/avatar-fallback.svg';

const MembersBody = (props) => {
  const item = props.data.href?.length > 0 ? props.data.href?.[0] : props.data;

  return (
    <>
      {item ? (
        <>
          <div className="member">
            <div className="left">
              <div className="image-wrapper">
                {item &&
                  (item.portrait || item?.image_field ? (
                    <img
                      src={item?.image_field ? flattenToAppURL(`${item['@id']}/@@images/portrait/preview`) : item.image ? flattenToAppURL(item.portrait.scales.preview.download) : avatarSVG}
                      alt=""
                    />
                  ) : (
                    <Icon name={avatarSVG} alt="" size="85px" />
                  ))}
              </div>
            </div>
            <div className="right">
              <div>
                <h2>{`${item.academic ?? ''} ${item.firstname ?? ''} ${item.name ?? ''}`}</h2>
                {!item.firstname && !item.name && <h2>{item?.title}</h2>}
              </div>
              <div className="details">
                {item?.location && (
                  <p>
                    <FormattedMessage id="Location" defaultMessage="Location" />: {item.location}
                  </p>
                )}
                {item?.phone && (
                  <p>
                    <FormattedMessage id="Phone" defaultMessage="Phone" />: {item.phone}
                  </p>
                )}
                {item?.fax && (
                  <p>
                    <FormattedMessage id="Fax" defaultMessage="Fax" />: {item.fax}
                  </p>
                )}
                {item?.email && (
                  <p>
                    <FormattedMessage id="Email" defaultMessage="Email" />: <a href={`mailto:${item.email}`}>{item.email}</a>
                  </p>
                )}
                {item?.website && (
                  <p>
                    <FormattedMessage id="Website" defaultMessage="Website" />: <a href={flattenToAppURL(item.website)}>{item.website}</a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default MembersBody;
