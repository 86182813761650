import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import MembersBlockData from './MembersBlockData';
import MembersBlockView from './View';

const MembersBlockEdit = (props) => {
  const { data, onChangeBlock, block, selected } = props;

  return (
    <>
      <MembersBlockView {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <MembersBlockData data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </>
  );
};

export default MembersBlockEdit;
