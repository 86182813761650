import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { map } from 'lodash';
import config from '@plone/volto/registry';
import { UniversalLink, Icon } from '@plone/volto/components';

import { getBlocksFieldname, getBlocksLayoutFieldname, hasBlocksData, getBaseUrl } from '@plone/volto/helpers';
import dgsfSiegelJPG from './dgsf-siegel.jpg';
import dgsfSiegelInstituteJPG from './dgsf-siegel-institute.jpg';
import dgsfSiegelEinrichtungenJPG from './dgsf-siegel-empfohlene-einrichtungen.jpg';
import fallbackAvatarSVG from 'volto-dgsf/icons/member-fallback.svg';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  fax: {
    id: 'Fax',
    defaultMessage: 'Fax',
  },
  firstname: {
    id: 'Firstname',
    defaultMessage: 'Firstname',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  homepage: {
    id: 'Homepage',
    defaultMessage: 'Homepage',
  },
  targetGroups: {
    id: 'DGSF certificates',
    defaultMessage: 'DGSF certificates',
  },
  weiterbildungsgaenge: {
    id: 'DGSF recognized training courses',
    defaultMessage: 'DGSF recognized training courses',
  },
  aboutTheMember: {
    id: 'About the member',
    defaultMessage: 'About the member',
  },
});

const mapNames = (name, type) =>
  ({
    'Systemische Beratung': [type === 'Fachleute' ? 'Systemische Beratung' : 'Lehrende/r für Systemische Beratung'],
    'Systemische Therapie und Beratung': [type === 'Fachleute' ? 'Systemische Therapie' : 'Lehrende/r für Systemische Therapie und Beratung'],
    'Systemische Mediation': [type === 'Fachleute' ? 'Systemische Mediation' : 'Lehrende/r für Systemische Mediation'],
    'Systemische Kinder- und Jugendlichentherapie': [type === 'Fachleute' ? 'Systemische Kinder- und Jugendlichentherapie' : 'Lehrende/r für Systemische Kinder- und Jugendlichentherapie'],
    'Systemische Sachverständigentätigkeit': [type === 'Fachleute' ? 'Systemische Sachverständigentätigkeit' : 'Lehrende/r für Systemische Sachverständigentätigkeit'],
    'Systemische Supervision': [type === 'Fachleute' ? 'Systemische Supervision' : 'Lehrende/r für Systemische Supervision'],
    'Systemisches Coaching': [type === 'Fachleute' ? 'Systemisches Coaching' : 'Lehrende/r für Systemische Coaching'],
    Multifamilientherapie: [type === 'Fachleute' ? 'Systemische Multifamilientherapie' : 'Lehrende/r für Systemische Multifamilientherapie'],
    'Systemische Organisationsentwicklung': [type === 'Fachleute' ? 'Systemische Organisationsentwicklung' : 'Lehrende/r für Systemische Organisationsentwicklung'],
    'Systemische Paartherapie und -beratung': [type === 'Fachleute' ? 'Systemische Paartherapie und -beratung' : 'Lehrende/r für Systemische Paartherapie und -beratung'],
  }[name] || name);

export const MemberView = ({ content, location }) => {
  const intl = useIntl();
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  const username = useSelector((state) => state.users.user.id);
  const username2 = useSelector((state) => state.users.user.username);

  return (
    <Container id="page-document" className="member-view">
      {content?.creators?.includes(username) ||
        (content?.creators?.includes(username2) && (
          <div className="document-links">
            {content?.is_institutional_member && (
              <UniversalLink href="/interne-dokumente/dokumenteinstitute" title="Dokumente für Institute">
                <button>Dokumentenbereich Institute</button>
              </UniversalLink>
            )}
            <UniversalLink href="/interne-dokumente/dokumentemitglieder" title="Dokumente für Mitglieder">
              <button>Dokumentenbereich Mitglieder</button>
            </UniversalLink>
          </div>
        ))}
      <div className="above the fold">
        {content?.is_institutional_member ? (
          content?.portrait && (
            <div className="image-wrapper institute">
              <img src={content.portrait.scales.teaser.download} alt="portrait" />
            </div>
          )
        ) : content?.portrait ? (
          <div className="image-wrapper">
            <img src={content.portrait.scales.teaser.download} alt="portrait" />
          </div>
        ) : (
          <div className="icon-wrapper">
            <Icon name={fallbackAvatarSVG} size="220px" className="placeholder" alt="" />
          </div>
        )}
        <div>
          <h1 className="documentFirstHeading">{content.title}</h1>
          {content?.praxis_name && <span className="praxis-name">{content.praxis_name}</span>}
        </div>
      </div>
      <div className="member-details">
        <div className="left">
          <h2>Über das Mitglied</h2>
          <div className="address">
            <p>
              {content?.academic ? `${content.academic} ` : ''}
              {content?.firstname} {content?.name}
            </p>
            {content?.praxis_name && <p>{content.praxis_name}</p>}
            {content?.street && <p>{content.street}</p>}
            {content?.city && <p>{content.city}</p>}
            {content?.country && <p>{content.country}</p>}
          </div>
          {content?.is_institutional_member
            ? (content?.phone || content?.fax) && (
                <div>
                  <div className="divider" />
                  <h2>Telefon/Fax</h2>
                  {content?.phone && (
                    <p>
                      Tel: <a href={`tel:${content.phone}`}>{content.phone}</a>
                    </p>
                  )}
                  {content?.fax && <p>Fax: {content.fax}</p>}
                </div>
              )
            : content?.phone && (
                <div>
                  <div className="divider" />
                  <h2>Telefon</h2>
                  <p>
                    Tel: <a href={`tel:${content.phone}`}>{content.phone}</a>
                  </p>
                </div>
              )}
          {content?.is_institutional_member
            ? (content?.website || content?.email) && (
                <div>
                  <div className="divider" />
                  <h2>Homepage/Email</h2>
                  {content?.email && (
                    <p>
                      E-Mail: <a href={`mailto:${content.email}`}>{content.email}</a>
                    </p>
                  )}
                  <p>
                    <UniversalLink href={content.website}>{content.website}</UniversalLink>
                  </p>
                </div>
              )
            : content?.website && (
                <div>
                  <div className="divider" />
                  <h2>Homepage</h2>
                  <UniversalLink href={content.website}>{content.website}</UniversalLink>
                </div>
              )}
        </div>
        <div className="right">
          <div className="certificates">
            <>
              <h2>DGSF-Zertifikate</h2>
              <ul>
                {content?.systemische_fachleute &&
                  content.systemische_fachleute.length > 0 &&
                  content.systemische_fachleute?.map((item) => {
                    return <li>{mapNames(item.title, 'Fachleute')}</li>;
                  })}
                {/* {content.mitglieder_mit_approbation?.map((item) => {
                      return <li>{item.title}</li>;
                    })} */}
                {content?.dgsf_lehrende &&
                  content.dgsf_lehrende.length > 0 &&
                  content.dgsf_lehrende?.map((item) => {
                    return <li>{mapNames(item.title, 'Lehrende')}</li>;
                  })}
                {content?.weiterbildungsinstitute &&
                  content.weiterbildungsinstitute.length > 0 &&
                  content.weiterbildungsinstitute?.map((item) => {
                    return <li>{mapNames(item.title, 'Fachleute')}</li>;
                  })}
                {content?.dgsf_empfohlene_einrichtungen &&
                  content.dgsf_empfohlene_einrichtungen.length > 0 &&
                  content.dgsf_empfohlene_einrichtungen?.map((item) => {
                    return <li>{mapNames(item.title, 'Lehrende')}</li>;
                  })}
              </ul>
            </>
          </div>
          {!content?.is_institutional_member && content?.systemische_fachleute?.length > 0 && (
            <div className="stamp">
              <div className="divider" />
              <h2>DGSF-Siegel</h2>
              <div className="image-wrapper">
                <img src={dgsfSiegelJPG} alt="Siegel für DGSF Anerkennung" />
              </div>
            </div>
          )}
          {content?.is_institutional_member &&
            (content?.dgsf_empfohlene_einrichtungen?.length > 0 ? (
              <div className="stamp">
                <div className="divider" />
                <h2>DGSF-Siegel</h2>
                <div className="image-wrapper">
                  <img className="einrichtung" src={dgsfSiegelEinrichtungenJPG} alt="Siegel für DGSF Anerkennung" />
                </div>
              </div>
            ) : content?.weiterbildungsinstitute?.length > 0 ? (
              <div className="stamp">
                <div className="divider" />
                <h2>DGSF-Siegel</h2>
                <div className="image-wrapper">
                  <img className="institute" src={dgsfSiegelInstituteJPG} alt="Siegel für DGSF Anerkennung" />
                </div>
              </div>
            ) : null)}
        </div>
      </div>
      {hasBlocksData(content) ? (
        map(content[blocksLayoutFieldname].items, (block) => {
          const Block = config.blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']]?.['view'] || null;
          if (config.blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']]?.id !== 'title') {
            return Block !== null ? (
              <Block key={block} id={block} properties={content} data={content[blocksFieldname][block]} path={getBaseUrl(location?.pathname || '')} />
            ) : (
              <div key={block}>
                {intl.formatMessage(messages.unknownBlock, {
                  block: content[blocksFieldname]?.[block]?.['@type'],
                })}
              </div>
            );
          }
        })
      ) : (
        <>
          {/* <h1 className="documentFirstHeading">{content.title}</h1> */}
          {/* {content.description && <p className="documentDescription">{content.description}</p>} */}
          {content.text && (
            <div
              dangerouslySetInnerHTML={{
                __html: content.text.data,
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};
