/**
 * Navigation actions.
 * @module actions/navigation/navigation
 */

import { GET_MEMBER_IMPORT } from 'volto-dgsf/constants/ActionTypes';

export default function getMemberImport(url, delete_after) {
  return {
    type: GET_MEMBER_IMPORT,
    request: {
      op: 'get',
      path: `@member-import?url=${url}&delete_after=${delete_after.toString()}`,
    },
  };
}
