import React from 'react';
import { useSelector } from 'react-redux';
import { parse } from 'date-fns';

export const replaceItemOfArray = (array, index, value) => Object.assign([...array], { [index]: value });

export const EffectiveDate = ({ item, type = 'effective' }) => {
  const language = useSelector((state) => state.intl.locale);

  return (
    <>
      {type === 'effective' ? (
        item?.EffectiveDate !== 'None' && item?.effective ? (
          <span className="day">
            {new Intl.DateTimeFormat(language, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(parse(item.effective))}
          </span>
        ) : (
          <span className="day"></span>
        )
      ) : type === 'start' ? (
        item?.start ? (
          <span className="day">
            {new Intl.DateTimeFormat(language, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(parse(item.start))}
          </span>
        ) : (
          <span className="day"></span>
        )
      ) : type === 'end' ? (
        item?.end ? (
          <span className="day">
            {new Intl.DateTimeFormat(language, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(parse(item.end))}
          </span>
        ) : (
          <span className="day"></span>
        )
      ) : null}
    </>
  );
};
