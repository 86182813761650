// import { MemberView } from './components/Theme/MemberView';
import MembersBlockView from './components/Blocks/Members/View';
import MembersBlockEdit from './components/Blocks/Members/Edit';
import { WeiterbildungsListingTemplate, MemberView, MemberListingTemplate, EventListingTemplate, DistanceFacet, DistanceFacetFilterListEntry, WeiterbildungView } from 'volto-dgsf/components';
import { defaultStylingSchema } from './components/Blocks/schema';
import { defineMessages } from 'react-intl';

import imagesSVG from '@plone/volto/icons/images.svg';
import separatorSVG from 'volto-dgsf/icons/separator.svg';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

const messages = defineMessages({
  passwortReset: {
    id: 'label_my_username_is',
    defaultMessage: 'Please enter your username',
  },
});

const DGSF_BG_COLORS = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'lightyellow', label: 'Light Yellow' },
  { name: 'yellow', label: 'Yellow' },
];

const applyConfig = (config) => {
  // Add here your project's configuration here by modifying `config` accordingly
  delete config.blocks.blocksConfig.imagesGrid;
  delete config.blocks.blocksConfig.teaserGrid;
  delete config.blocks.blocksConfig.hero;
  delete config.blocks.blocksConfig.html;

  config.settings = {
    ...config.settings,
    isMultilingual: false,
    listingPreviewImageField: 'preview_image',
    supportedLanguages: ['de'],
    defaultLanguage: 'de',
    navDepth: 1,
    showSelfRegistration: false,
  };

  config.blocks.blocksConfig.__grid = {
    ...config.blocks.blocksConfig.__grid,
    enableStyling: true,
    colors: DGSF_BG_COLORS,
    stylesSchema: defaultStylingSchema,
    gridAllowedBlocks: ['teaser', 'image', 'slate'],
    // One could customize the blocks inside the grid like this:
    blocksConfig: {
      ...config.blocks.blocksConfig,
      teaser: {
        ...config.blocks.blocksConfig.teaser,
        enableStyling: false,
      },
    },
  };

  config.blocks.blocksConfig.members = {
    id: 'members',
    title: 'Mitglieder',
    icon: imagesSVG,
    view: MembersBlockView,
    edit: MembersBlockEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.separator = {
    ...config.blocks.blocksConfig.separator,
    icon: separatorSVG,
  };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'weiterbildungsListing',
        title: 'Weiterbildungen',
        template: WeiterbildungsListingTemplate,
      },
      {
        id: 'memberListing',
        title: 'Mitglieder',
        template: MemberListingTemplate,
      },
      {
        id: 'eventListing',
        title: 'Termine/Nachrichten',
        template: EventListingTemplate,
      },
    ],
  };

  config.settings.DSGVOBanner = {
    ...config.settings.DSGVOBanner,
    tracker: {
      type: 'none',
      id: 0,
    },
    modules: ['youtube', 'facebook'],
    privacy_url: '/datenschutz',
  };

  config.blocks.blocksConfig.search.extensions.facetWidgets.types = [
    ...config.blocks.blocksConfig.search.extensions.facetWidgets.types,
    {
      id: 'distanceFacet',
      title: 'Distance',
      view: DistanceFacet,
      isDefault: false,
      stateToValue: DistanceFacet.stateToValue,
      valueToQuery: DistanceFacet.valueToQuery,
      filterListComponent: DistanceFacetFilterListEntry,
    },
  ];
  config.views.contentTypesViews.Weiterbildung = WeiterbildungView;
  config.views.contentTypesViews.Member = MemberView;

  return config;
};

export default applyConfig;
